<template>
  <div>
    <div class="publishFC">
      <div class="main">
        <div class="content">
          <span class="content-title">笔记内容: </span>
          <textarea
            placeholder="描绘一下此刻的心情"
            style="
              border: 1px dashed black;
              background-color: transparent;
              padding: 20px;
            "
            v-model="c_content"
          ></textarea>
        </div>
        <div class="upload_img-module">
          <el-upload
            action="http://www.soutudashi.com/api/upload_friendcicle"
            :auto-upload="false"
            show-file-list
            list-type="picture-card"
            :limit="3"
            ref="upload"
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
            :on-remove="handleRemove"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选择图片</el-button
            >
          </el-upload>
          <!-- <input
            type="file"
            class="file_input"
            multiple
            id="fileInput"
            @change="uploadImage"
          />
          <label class="upload_btn" for="fileInput">上传图片</label> -->
          <!-- <div class="img-list">
            <div class="img-item" v-for="(item, index) in uploaded_img_list">
              <img
                class="img-as"
                :src="base_url + item.filename"
                alt="Image Preview"
                :key="index"
                :class="getImgClassName(index)"
              />
              <div class="img-close-btn" @click="close_img($event, index)">
                <i class="el-icon-close"></i>
              </div>
            </div>
          </div> -->
        </div>

        <div class="interact">
          <a
            href="/community"
            class="publish-btn"
            @click.prevent="publish_circle($event)"
            >发布</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { getUserInfo, get_token } from "../common/common.js";
export default {
  data() {
    return {
      uploaded_img_list: [],
      c_content: "",
      fileList: [],
    };
  },
  methods: {
    handleRemove() {
      // console.log("handleremovie: ", this.fileList);
    },
    handleSuccess() {
      // console.log("handleSuccess: ", this.fileList);
    },
    // beforeUpload() {
    //   console.log("beforeUpload: ", this.fileList);
    // },
    beforeUpload(file) {
      this.fileList.push(file.name);
      const fileName = file.name;
      // console.log("上传文件的名字：", fileName, file);
      return true; // 返回true表示允许上传，返回false表示阻止上传
    },
    get_cur_user() {
      // console.log("ddsgdg : ", getUserInfo(), this.cur_circle_data);
      return getUserInfo();
    },

    getImgClassName(index) {
      if (index <= 5) {
        return "first-line";
      } else {
        return "last-line";
      }
    },

    //点击关闭图片
    close_img(e, index) {
      // console.log("删除图片： ", e, e.target.parentNode.parentNode, index);
      if (index > -1 && index < this.uploaded_img_list.length) {
        // console.log("删除前: ", this.uploaded_img_list);
        this.uploaded_img_list.splice(index, 1);
        // console.log("删除后: ", this.uploaded_img_list);
      }
    },

    message_box(type, message) {
      this.$message({
        showClose: true,
        message: message,
        type: type,
        customClass: "messageIndex",
      });
    },

    // 添加图片 但未上传
    // uploadImage(event) {
    //   const file_list = event.target.files;
    //   //   console.log("filepath: ", filePath, " dddd ", fileInput); // 在控制台输出图片路径
    //   for (let index = 0; index < file_list.length; index++) {
    //     const file = file_list[index];

    //     // 判断上传的文件是否为图片，不过就给个告警，并跳过当前图片
    //     if (!this.isImage(file)) {
    //       this.message_box("error", "上传的文件应是图片");
    //       continue;
    //     } else if (!this.fileSizeLegal(file)) {
    //       this.message_box("error", "上传的文件大小应小于等于1M");
    //       continue;
    //     }
    //     const fileName = Date.now() + "-" + file.name;
    //     this.ossClient
    //       .put(fileName, file)
    //       .then((res) => {
    //         console.log("上传成功", res);
    //         this.uploaded_img_list.push({ filename: fileName, file: file });
    //       })
    //       .catch((err) => {
    //         console.error("上传失败", err);
    //       });
    //   }

    //   event.target.value = "";
    // },
    // 判断上传的文件是否为图片
    isImage(file) {
      const imageExtensions = [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "bmp",
        "tiff",
        "tif",
        "webp",
        "svg",
      ];

      const fileExtension = file.name.split(".").pop().toLowerCase();
      return imageExtensions.includes(fileExtension);
    },
    // 判断上传的文件大小是否合法
    fileSizeLegal(file) {
      // console.log("当前文件的大小: ", file.size);
      return file.size / 1024 <= 1024 * 5 ? true : false;
    },

    // 发布朋友圈
    async publish_circle(event) {
      await this.$refs.upload.submit();

      var pic_list = [];
      for (let i = 0; i < this.uploaded_img_list.length; i++) {
        pic_list.push(
          CONSTANT.OSS_BASE_URL + this.uploaded_img_list[i]["filename"]
        );
      }

      const data = {
        // nick_name: this.get_cur_user().nick_name,
        content: this.c_content,
        fileList: this.fileList,
      };

      // console.log("发布朋友圈前: ", data);

      // 控制上传的内容中有图片或文字描述
      if (
        this.uploaded_img_list.length <= 0 &&
        this.c_content.trim().length <= 0
      ) {
        this.message_box("error", "请输入内容或上传图片噢");
        // event.stopPropagation();
        return;
      }
      try {
        const token = get_token("token");
        var response = await this.$http({
          method: "post",
          url: "http://www.soutudashi.com/add_friend_circle", // 拼接URL和参数
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", // 设置跨域参数
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${token}`,
          },
          data: data,
          responseType: "json",
        });

        // var response = await this.$http.post(
        //   "http://www.soutudashi.com/add_friend_circle",
        //   {
        //     headers: { "Content-Type": "application/json" },
        //     data,
        //     // data,
        //   }
        // );

        // if (response.data.main.length == 0) {
        //   this.message_box("error", "该用户还没有在本站注册，请先注册");
        // } else {
        // console.log("评论接口返回结果response: friend ", response);

        // console.log("dddddddddsdfhdfhdf: ", response.data);

        // const res = response.data;
        // this.cur_circle_data.children.unshift({
        //   comment_id: res.comment_id,
        //   target_id: res.target_id,
        //   content: res.content,
        //   publish_time: res.create_time,
        //   nick_name: res.nick_name,
        //   avatar_url: res.avatar_url,
        //   child_comment_cnt: res.child_comment_cnt,
        //   like_cnt: res.like_cnt,
        //   is_like: res.is_like,
        // });
        return response.data;
        // }
      } catch (e) {
        console.log(e);
      } finally {
        this.c_content = "";
        this.$router.push("/community");
        // this.comment_content = "";
      }
    },
  },
  created() {},
};
</script>
<style scoped>
.publishFC {
  width: 100%;
  height: 100%;
  /* background-color: red; */
}

.bg {
  position: relative;
  width: 600px;
  /* height: 600px; */
  /* background-color: black; */
  min-height: 500px;
  margin: 0px auto;
}

.publishFC > .main {
  position: relative;
}

.publishFC > .main > .content {
  width: 1120px;
  margin: 20px auto;
  /* display: flex;
  flex-direction: column;
  justify-content: left; */
  text-align: left;
}

.publishFC > .main > .interact {
  width: 1120px;
  margin: 20px auto;
  /* display: flex;
  flex-direction: column;
  justify-content: left; */
  /* text-align: left; */
}
.publishFC > .main > .content > .content-title {
  font-size: 22px;
  position: relative;
  display: block;
  margin-bottom: 10px;
}

.publishFC > .main > .content > textarea {
  min-height: 100px;
  width: 1118px;
  position: relative;
  /* margin: 0px auto; */
}
.upload_img-module {
  margin: 20px auto 20px auto;
  width: 1120px;
  text-align: left;
}

/* .el-upload--picture-card {
  background-color: transparent !important;
} */

.img-list {
  margin-top: 20px;
  width: auto;
  min-height: 180px;
  border: 1px dashed black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

input[type="file"] {
  display: none;
}

input[type="file"] + label {
  background-color: green;
  padding: 10px 20px;
  width: 110px;
  height: 45px;
  border: 1px solid #ccc;
  cursor: pointer;
  box-sizing: border-box;
}

.upload_btn {
  display: block;
  border-radius: 20px;
  color: white;
}

.upload_btn:hover {
  scale: 1.1;
}

.img-list img {
  width: 200px;
  height: 150px;
}

.img-list img.first-line {
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.img-list img.last-line {
  margin-left: 20px;
  /* margin-top: 20px; */
  margin-bottom: 20px;
}

.img-list .img-item {
  position: relative;
}
.problem-describe {
  /* display: flex; */
  width: 100%;
}

.problem-describe span {
  float: left;
  display: block;
}

.problem-area {
  width: 100%;
  margin-top: 20px;
  border: 1px dashed black;
}
.submit_btn {
  margin: 20px auto;
  background-color: green;
  /* padding: 10px 20px; */
  width: 100px;
  height: 45px;
  border: 1px solid #ccc;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 18px;
}

.submit_btn:hover {
  scale: 1.1;
}

.img-close-btn {
  position: absolute;
  right: 0px;
  top: 20px;
  width: 25px;
  height: 25px;
}

.img-close-btn i {
  width: 100%;
  height: 100%;
  font-size: 25px;
  line-height: 25px;
}

.img-close-btn i:hover {
  transform: rotate(180deg);
  transition: transform 0.5s;
}

.interact > .publish-btn {
  /* display: block; */
  width: 120px;
  height: 60px;
  background-color: pink;
  display: block;
  border-radius: 10px;
  text-decoration-line: none;
  line-height: 60px;
}

.messageIndex {
  z-index: 999999 !important;
}
</style>
